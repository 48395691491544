import { Navigate, Routes as ReactRoutes, Route } from "react-router-dom";
import NotFound from "views/NotFound/NotFound";
import Home from "./views/Home";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route key="home" path="/" element={<Home />} />
      <Route key="notfound" path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </ReactRoutes>
  );
};

export default Routes;
