import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Stack } from "@mui/material";
import { orange } from "@mui/material/colors";
import Container from "components/Container";
import Main from "layouts/Main";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { t } = useTranslation();

  return (
    <Main>
      <Box
        bgcolor={theme.palette.alternate.main}
        position={"relative"}
        minHeight={"calc(100vh - 247px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={1}
        marginTop={-12}
        paddingTop={12}
      >
        <Container>
          <Grid container>
            <Grid
              item
              container
              alignItems={"center"}
              justifyContent={"center"}
              xs={12}
              md={6}
            >
              <Box>
                <Typography
                  variant="h1"
                  component={"h1"}
                  align={isMd ? "left" : "center"}
                  sx={{ fontWeight: 700 }}
                >
                  404
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  color="text.secondary"
                  align={isMd ? "left" : "center"}
                >
                  {t("404.title")}
                  <br />
                  {t("404.description")}
                </Typography>
                <Box
                  marginTop={4}
                  display={"flex"}
                  justifyContent={{ xs: "center", md: "flex-start" }}
                >
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    size="large"
                    href={"/"}
                  >
                    {t("home")}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              xs={12}
              md={6}
              alignItems="center"
            >
              <Stack
                height={1}
                width={1}
                maxWidth={500}
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
              >
                <DirectionsRunIcon sx={{ fontSize: 400, color: orange[900] }} />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Main>
  );
};

export default NotFound;
