import { HashRouter } from "react-router-dom";
import Page from "./components/Page";
import Routes from "./routes";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "aos/dist/aos.css";

const App = () => {
  return (
    <Page>
      <HashRouter>
        <Routes />
      </HashRouter>
    </Page>
  );
};

export default App;
