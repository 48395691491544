import { ArrowDownward } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import data from "./data.json";

const allowedUsers = [
  "matthieu",
  "alice",
  "jo",
  "gui",
  "leo",
  "julie",
  "xabi",
  "silvia",
  "maider",
  "laeti",
  "amandine",
  "clem",
  "enora",
  "gaspi",
  "anais",
];

function TextWithLineBreaks(props) {
  const textWithBreaks = props.text?.split("\n").map((text, index) => (
    <>
      {text}
      <br />
    </>
  ));

  return <div>{textWithBreaks}</div>;
}
const Home = () => {
  const [currentData, setCurrentData] = useState(null);
  const [flipIndex, setFlipIndex] = useState(0);
  const [flipping, setFlipping] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const userCookie = Cookies.get("user");
    if (userCookie) {
      setUser(userCookie);
    }

    // Récupérer le paramètre 'day' de l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const dayParam = urlParams.get("day");

    // Si le paramètre 'day' est présent, utiliser ce jour, sinon utiliser le jour actuel
    const today = dayParam ? parseInt(dayParam) : new Date().getDate();
    const todayData = data.find((entry) => parseInt(entry.day) === today);
    setCurrentData(todayData || { theme: "Pas de thème", default: "..." });
  }, []);

  const handleLogin = () => {
    if (!username) {
      setError("Veuillez entrer un nom d'utilisateur.");
      return;
    }

    if (allowedUsers.includes(username.toLowerCase())) {
      const userData = username.toLowerCase();
      setUser(userData);
      Cookies.set("user", userData, { expires: 24 });
      setError("");
      setFlipIndex(0);
    } else {
      setError("Désolé, pas de calendrier de l'avent pour toi");
    }
  };

  const handleLogout = () => {
    setUser(null);
    Cookies.remove("user");
  };

  const getCards = () => {
    const name = user?.charAt(0).toUpperCase() + user?.slice(1);
    const content1 = (currentData?.theme || "Chargement...")?.replace(
      "${user}",
      name
    );
    const content2 = (currentData?.[user] || currentData?.default)?.replace(
      "${user}",
      name
    );

    // Découper le texte sur le "?" si présent
    const splitContent2 = content2?.includes("?")
      ? content2.split("?").concat(" ?")
      : [content2, ""]; // Split avant et après le "?"

    const containsQuestionMark =
      content1?.includes("?") || content2?.includes("?");

    const resuslt = [
      {
        title: `Jour n°${currentData?.day}`,
        backgroundColor: "#FFE0B2", // Pastel peach
        content: currentData?.themeDescription?.replace("${user}", name),
      },
      {
        title: `${currentData?.theme}`,
        backgroundColor: "#C8E6C9", // Pastel green
        content: splitContent2[0], // Texte avant le "?"
      },
    ];

    if (containsQuestionMark) {
      resuslt.push({
        title: `Solution`,
        backgroundColor: "#BBDEFB", // Pastel blue
        content: splitContent2[1], // Texte après le "?"
      });
    }

    return resuslt;
  };

  const cards = getCards();

  const handleFlip = () => {
    if (flipping) return;
    setFlipping(true);
    setTimeout(() => {
      setFlipIndex((prevIndex) => (prevIndex + 1) % cards.length);
      setFlipping(false);
    }, 400);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F0F4F8", // Light background for the overall page
        position: "relative",
      }}
    >
      {user && (
        <Button
          variant="contained"
          color="secondary"
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            backgroundColor: "#1976D2", // Blue
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1565C0",
            },
            // Masquer le bouton sur les petits écrans (mobile)
            display: {
              xs: "none", // "xs" correspond aux écrans de taille mobile
              md: "block", // "md" et plus grande taille, le bouton est affiché
            },
          }}
          onClick={handleLogout}
        >
          Se déconnecter
        </Button>
      )}

      {!user && (
        <Card
          sx={{
            width: "300px",
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FAFAFA", // Soft grey
            color: "#000",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "16px",
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Connexion
            </Typography>
            <TextField
              label="Nom d'utilisateur"
              variant="outlined"
              sx={{ marginBottom: 2 }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{
                backgroundColor: "#64B5F6", // Soft blue
                "&:hover": {
                  backgroundColor: "#42A5F5", // Darker blue on hover
                },
              }}
            >
              Se connecter
            </Button>
            {error && (
              <Alert severity="error" sx={{ marginTop: 2 }}>
                {error}
              </Alert>
            )}
          </CardContent>
        </Card>
      )}

      {user && (
        <Box
          sx={{
            position: "relative",
            perspective: "1000px",
            width: {
              xs: "90%", // Sur mobile, la carte occupera 90% de la largeur de l'écran
              sm: "500px", // Sur tablettes et plus grands, la carte restera à 300px
            },
            height: {
              xs: "600px", // Sur mobile, la hauteur sera ajustée automatiquement en fonction du contenu
              sm: "600px", // Sur tablettes et plus grands, la hauteur restera à 400px
            },
            margin: "0 auto",
          }}
          onClick={handleFlip}
        >
          {cards.map((card, index) => (
            <Card
              key={index}
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                backfaceVisibility: "hidden",
                transformStyle: "preserve-3d",
                transform:
                  flipIndex === index ? "rotateY(0deg)" : "rotateY(180deg)",
                transition: "transform 0.6s",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: card.backgroundColor,
                color: "#000",
                cursor: "pointer",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5" sx={{ marginBottom: 4 }}>
                  {card.title}
                </Typography>

                {card.content &&
                /\.(jpg|jpeg|png|gif|bmp)$/i.test(card.content) ? (
                  <img
                    src={card.content}
                    alt={card.title}
                    style={{
                      maxWidth: "200%",
                      maxHeight: "500px",
                      marginBottom: "16px",
                    }}
                  />
                ) : (
                  <TextWithLineBreaks text={card.content} />
                )}

                {index < cards.length - 1 && (
                  <IconButton
                    sx={{
                      marginTop: 2,
                      color: "#1976D2", // Blue arrow
                      fontSize: "30px",
                    }}
                  >
                    <ArrowDownward />
                  </IconButton>
                )}
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Home;
